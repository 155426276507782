import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useCallback, useEffect, useState } from "react";
import { translate } from "../../../../preferences/localization";
import { MutableOnlyArray } from "../../../../utils/dataTypes";
import PromptDialog from "../../prompt/PromptDialog";
import { SXProps } from "../Button/dataTypes";
import { InputProps } from "./dataTypes";

const INPUT_STYLE = {
  '& input[type="search"]::-webkit-search-cancel-button': {
    opacity: 0,
  },
  '&:hover input[type="search"]::-webkit-search-cancel-button': {
    opacity: 0,
  },
};

const ON_SHOW_CLEAR_BUTTON_STYLE = {
  "& .MuiButtonBase-root": {
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
  "&:hover .MuiButtonBase-root": {
    opacity: 1,
  },
};

const ON_END_ADORNMENT_ICON = {
  "& .MuiInputBase-root": {
    paddingRight: "2px",
  },
};

const InputField = <TPromptItem,>({
  customCssClass,
  showClearButton,
  onClear,
  usePrompt,
  PromptComponent,
  onSelectPromptRow,
  sx: sxProp,
  ...rest
}: InputProps<TPromptItem>) => {
  const [shrinkLabel, setShrinkLabel] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);

  const sx: MutableOnlyArray<SXProps> = [
    {
      ...INPUT_STYLE,
      ...(usePrompt || showClearButton
        ? {
            ...ON_END_ADORNMENT_ICON,
          }
        : {}),
    },
  ];

  if (Array.isArray(sxProp)) sx.push(...sxProp);
  else if (sxProp) sx.push(sxProp);

  useEffect(() => {
    if (rest.value !== "" && rest.value != null) {
      setShrinkLabel(true);
    }
  }, [rest.value]);

  const handleClear = useCallback(() => {
    onClear?.();
    setShrinkLabel(false);
  }, [onClear]);

  const handleFocus = useCallback(() => {
    setShrinkLabel(true);
  }, [setShrinkLabel]);

  const closePrompt = useCallback(() => {
    setPromptOpen(false);
  }, []);

  return (
    <>
      <TextField
        className={customCssClass}
        {...rest}
        onFocus={handleFocus}
        onBlur={e => e.target.value === "" && setShrinkLabel(false)}
        InputProps={{
          ...rest.InputProps,
          endAdornment: (
            <>
              {rest.value != null && rest.value !== "" && showClearButton && (
                <Box sx={ON_SHOW_CLEAR_BUTTON_STYLE}>
                  <IconButton onClick={handleClear}>
                    <Tooltip title={translate("CLEAR")}>
                      <ClearIcon sx={{ cursor: "pointer" }} />
                    </Tooltip>
                  </IconButton>
                </Box>
              )}
              {usePrompt && (
                <IconButton onClick={() => setPromptOpen(true)}>
                  <Tooltip title={translate("SEARCH")}>
                    <ManageSearchIcon sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </IconButton>
              )}
              {rest.InputProps?.endAdornment}
            </>
          ),
        }}
        InputLabelProps={{ shrink: shrinkLabel }}
        sx={sx}
      />
      {usePrompt && (
        <PromptDialog
          onSelectRow={onSelectPromptRow}
          PromptComponent={PromptWithExtraProp(PromptComponent, onSelectPromptRow, closePrompt)}
          promptOpen={promptOpen}
          closePrompt={closePrompt}
        />
      )}
    </>
  );
};

const PromptWithExtraProp = <TPromptItem,>(
  Component: React.ComponentType<{ onSelect: (item: TPromptItem) => void; closePrompt: () => void }>,
  onSelect: (item: TPromptItem) => void,
  closePrompt: () => void,
) => {
  const WrappedComponent = () => <Component onSelect={onSelect} closePrompt={closePrompt} />;

  return WrappedComponent;
};

export default InputField;
