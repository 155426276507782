import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useCallback } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setInfos } from "../../features/dialog/dialogSlice";
import { TimeLimit } from "../../features/timeLimit/dataTypes";
import useDeleteTimeLimit from "../../features/timeLimit/hooks/useDeleteTimeLimit";
import useTranslate from "../../language/useTranslate";
import Button from "../shared/PostNord/Button/Button";
import InputField from "../shared/PostNord/InputField/InputField";

const TimeLimitDeleteDialog = ({
  timeLimitToDelete,
  setTimeLimitToDelete,
  refetchTimeLimits,
}: {
  timeLimitToDelete?: TimeLimit;
  setTimeLimitToDelete: (timeLimit: TimeLimit | undefined) => void;
  refetchTimeLimits: () => void;
}) => {
  const appDispatch = useAppDispatch();
  const translate = useTranslate();

  const { deleteTimeLimit, isDeletingTimeLimit } = useDeleteTimeLimit();

  const handleDeleteTimeLimit = useCallback(async () => {
    if (timeLimitToDelete == null) return;

    const result = await deleteTimeLimit({ timeLimitId: timeLimitToDelete.id });

    if (result?.success) {
      appDispatch(setInfos([{ code: "time.limit.deleted", title: translate("DELETE_SUCCESSFUL") }]));
      setTimeLimitToDelete(undefined);
      refetchTimeLimits();
    }
  }, [appDispatch, deleteTimeLimit, refetchTimeLimits, setTimeLimitToDelete, timeLimitToDelete, translate]);

  return (
    <Dialog
      open={timeLimitToDelete != null}
      onClose={() => setTimeLimitToDelete(undefined)}
      PaperProps={{ sx: { width: "-webkit-fill-available" } }}
    >
      <DialogTitle>{translate("CONFIRM_DELETION")}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <InputField
          label={translate("OWNER_ID")}
          value={timeLimitToDelete?.ownerID}
          disabled
          sx={{ marginTop: "1rem" }}
        />
        <InputField label={translate("OWNER_TYPE")} value={timeLimitToDelete?.ownerType} disabled />
        <InputField label={translate("TYPE")} value={timeLimitToDelete?.type} disabled />
        <InputField type="number" label={translate("DAYS")} value={timeLimitToDelete?.days} disabled />

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box display="flex" flexDirection="row" alignItems="flex-end">
            <Button label={translate("CANCEL")} onClick={() => setTimeLimitToDelete(undefined)} />
            <Button
              label={translate("OK")}
              isLoading={isDeletingTimeLimit}
              onClick={() => {
                handleDeleteTimeLimit();
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TimeLimitDeleteDialog;
