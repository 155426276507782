import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { Except } from "type-fest";
import { TIME_LIMIT_FIELDS, TimeLimit } from "../../features/timeLimit/dataTypes";
import useTranslate from "../../language/useTranslate";
import { COLUMN_TYPES } from "../DataGrid/column/columnTypes";

const STANDARD_COLUMN_DEF: Readonly<Except<GridColDef, "type" | "field">> = {
  editable: false,
  flex: 1,
};

export default function useTimeLimitColumns(setTimeLimitToDelete: (timeLimit: TimeLimit) => void): GridColDef[] {
  const translate = useTranslate();

  return useMemo(
    () => [
      {
        ...STANDARD_COLUMN_DEF,
        field: TIME_LIMIT_FIELDS.OWNER_I_D,
        headerName: translate("OWNER_ID"),
        type: COLUMN_TYPES.STRING,
      },
      {
        ...STANDARD_COLUMN_DEF,
        field: TIME_LIMIT_FIELDS.OWNER_TYPE,
        headerName: translate("OWNER_TYPE"),
        type: COLUMN_TYPES.STRING,
      },
      {
        ...STANDARD_COLUMN_DEF,
        field: TIME_LIMIT_FIELDS.TYPE,
        headerName: translate("TYPE"),
        type: COLUMN_TYPES.STRING,
      },
      {
        editable: false,
        width: 150,
        field: TIME_LIMIT_FIELDS.DAYS,
        headerName: translate("DAYS"),
        type: COLUMN_TYPES.NUMBER,
      },
      {
        ...STANDARD_COLUMN_DEF,
        field: "delete",
        type: "actions",
        headerName: translate("DELETE"),
        width: 100,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              key={id}
              icon={<DeleteIcon />}
              label={translate("DELETE")}
              onClick={() => setTimeLimitToDelete(row)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [setTimeLimitToDelete, translate],
  );
}
