import { useCallback, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { REQUEST_STATE } from "../../dataTypes";
import { deleteTimeLimit } from "../reducers/deleteTimeLimit";
import { selectErrorMessage, selectRequestState } from "../timeLimitSelectors";

const useDeleteTimeLimit = () => {
  const dispatch = useAppDispatch();
  const forceUpdate = useForceUpdate();

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isDeleting = useRef(false);

  const doDeleteTimeLimit = useCallback(
    async ({ timeLimitId }: { timeLimitId?: string }) => {
      if (isDeleting.current || isLoading || timeLimitId == null) return;

      forceUpdate();

      const result = await dispatch(deleteTimeLimit({ timeLimitId }));

      isDeleting.current = false;

      return { success: result.meta.requestStatus === "fulfilled" };
    },
    [dispatch, forceUpdate, isLoading],
  );

  return {
    isUninitialized,
    isDeletingTimeLimit: isDeleting.current || isLoading,
    isSuccess,
    isError,
    errorMessage,
    deleteTimeLimit: doDeleteTimeLimit,
  };
};

export default useDeleteTimeLimit;
