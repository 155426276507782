import { createSelector } from "@reduxjs/toolkit";
import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { TimeLimit, timeLimitSliceName } from "./dataTypes";
import { timeLimitAdapter } from "./timeLimitAdapter";

const selectSliceState = (state: RootState) => state[appDataName][timeLimitSliceName];

const adapterSelectors = timeLimitAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectTimeLimitEntities = adapterSelectors.selectEntities;
export const selectTimeLimits = adapterSelectors.selectAll;
export const makeSelectTimeLimit = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);

export function makeSelectTimeLimitsById(ids: string[] = []) {
  return createSelector(selectTimeLimitEntities, entities => {
    return ids.reduce<TimeLimit[]>((timeLimits, id) => {
      const timeLimit = entities[id];

      return timeLimit ? [...timeLimits, timeLimit] : timeLimits;
    }, []);
  });
}
