import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { timeLimitSliceName } from "./dataTypes";
import { addCreateTimeLimitsReducers } from "./reducers/createTimeLimit";
import { addDeleteTimeLimitsReducers } from "./reducers/deleteTimeLimit";
import { addFetchTimeLimitsReducers } from "./reducers/fetchTimeLimits";
import { timeLimitAdapter } from "./timeLimitAdapter";

const slice = createSlice({
  name: timeLimitSliceName,
  initialState: timeLimitAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addCreateTimeLimitsReducers(builder);
    addDeleteTimeLimitsReducers(builder);
    addFetchTimeLimitsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
