import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { AdditionalPaginationState, EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { TimeLimit, timeLimitSliceName } from "../dataTypes";
import { timeLimitAdapter } from "../timeLimitAdapter";

async function doDeleteTimeLimitRequest(dispatch: AppDispatch, { timeLimitId }: { timeLimitId: string }) {
  const request = new Request(api.timeLimit.deleteTimeLimit(timeLimitId), Method.POST);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const deleteTimeLimit = createAsyncThunk<void, { timeLimitId: string }>(
  timeLimitSliceName + "/deleteTimeLimitFile",
  doDeleteTimeLimitRequest,
);

export const addDeleteTimeLimitsReducers = (
  builder: EntityActionReducerBuilderInterface<TimeLimit, AdditionalPaginationState>,
) => {
  builder.addCase(deleteTimeLimit.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(deleteTimeLimit.fulfilled, (state, action) => {
    timeLimitAdapter.removeOne(state, action.meta.arg.timeLimitId);
    state.requestState = REQUEST_STATE.FULFILLED;
    state.count -= 1;
  });
  builder.addCase(deleteTimeLimit.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
