import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { GetTimeLimitsFilter, ResponseTimeLimit, TimeLimit, timeLimitSliceName } from "../dataTypes";
import { timeLimitAdapter } from "../timeLimitAdapter";

const getTimeLimits = async (dispatch: AppDispatch, { filter }: { filter: Readonly<GetTimeLimitsFilter> }) => {
  const url = api.timeLimit.getTimeLimits();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  timeLimits: PaginationResponse<ResponseTimeLimit>;
}

export const fetchTimeLimits = createAsyncThunk<
  Response,
  { filter: Readonly<GetTimeLimitsFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(timeLimitSliceName + "/fetchTimeLimits", getTimeLimits);

export const addFetchTimeLimitsReducers = (
  builder: EntityActionReducerBuilderInterface<TimeLimit, AdditionalPaginationState>,
) => {
  builder.addCase(fetchTimeLimits.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchTimeLimits.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const timeLimits = payload.timeLimits.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        timeLimitAdapter.setAll(state, timeLimits);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        timeLimitAdapter.upsertMany(state, timeLimits);
        break;
    }

    state.count = payload.timeLimits.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchTimeLimits.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
