import { CamelCase, SnakeCase } from "type-fest";
import { AdditionalRequestState, IdObject } from "../dataTypes";
import { PaginatedRequestFilter } from "../utils/pagination";

export const timeLimitSliceName = "timeLimit";

export interface CreateTimeLimitRequest {
  ownerType: string;
  ownerID: string;
  type: string;
  days: number;
}

export interface ResponseTimeLimit extends IdObject {
  ownerType: string;
  ownerID: string;
  type: string;
  days: number;
}

export interface GetTimeLimitsFilter extends PaginatedRequestFilter {}

export interface TimeLimit extends ResponseTimeLimit, AdditionalRequestState {}

export const TIME_LIMIT_FIELDS: {
  [key in Uppercase<SnakeCase<Exclude<keyof ResponseTimeLimit, "id">>>]: CamelCase<Lowercase<key>>;
} = {
  OWNER_TYPE: "ownerType",
  OWNER_I_D: "ownerID",
  TYPE: "type",
  DAYS: "days",
};
