import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { useRef, useState } from "react";
import useTranslate from "../../language/useTranslate";
import Page from "../shared/Page/Page";
import Button from "../shared/PostNord/Button/Button";
import TimeLimitCreateDialog from "./TimeLimitCreateDialog";
import TimeLimitDataGrid, { ImportedFilesRef } from "./TimeLimitDataGrid";

const TimeLimitPage = () => {
  const translate = useTranslate();
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const dataGridRef = useRef<ImportedFilesRef>(null);

  const handleRefetchWhenTimeLimitCreated = () => {
    setCreateDialogOpen(false);

    if (dataGridRef.current) {
      dataGridRef.current.reload();
    }
  };

  return (
    <Page spacingTop>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h1" marginBottom={"1.6rem"}>
          {translate("TIME_LIMITS")}
        </Typography>
        <Button
          label={translate("NEW_TIME_LIMIT")}
          onClick={() => setCreateDialogOpen(true)}
          variant={"outlined"}
          startIcon={<AddIcon />}
        />
      </Box>
      <Box marginBottom={"2rem"}>
        <TimeLimitDataGrid ref={dataGridRef} />
      </Box>
      <TimeLimitCreateDialog
        createDialogOpen={createDialogOpen}
        setCreateDialogOpen={setCreateDialogOpen}
        refetchTimeLimits={handleRefetchWhenTimeLimitCreated}
      />
    </Page>
  );
};

export default TimeLimitPage;
