import { GridFilterModel, GridPaginationModel, GridSortModel } from "@mui/x-data-grid-premium";
import { forwardRef, PropsWithoutRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useImmerReducer } from "use-immer";
import { FULFILLED_UPDATE_METHOD } from "../../features/dataTypes";
import { PURCHASE_ORDER_FIELDS } from "../../features/purchaseOrder/dataTypes";
import { GetTimeLimitsFilter, TimeLimit } from "../../features/timeLimit/dataTypes";
import useGetTimeLimits from "../../features/timeLimit/hooks/useGetTimeLimits";
import { ORDER_BY_OPERATOR } from "../../features/utils/SqlOperators";
import DataGrid from "../DataGrid/DataGrid";
import { DEFAULT_PAGINATION_MODEL } from "../DataGrid/config/DataGridConfig";
import {
  FILTER_MODEL_ACTION as ACTION,
  getOrderByModel,
  getPaginatedRequestFilter,
  getWhereFilterByModelAndHandleDateEQ,
  filterModelReducer as reducer,
} from "../DataGrid/util/DataGridUtil";
import TimeLimitDeleteDialog from "./TimeLimitConfirmDeleteDialog";
import useTimeLimitColumns from "./useTimeLimitColumns";

export interface ImportedFilesRef {
  reload: () => void;
}
type Props = PropsWithoutRef<unknown>;

const TimeLimitDataGrid = forwardRef<ImportedFilesRef, Props>((_, ref) => {
  const [timeLimitToDelete, setTimeLimitToDelete] = useState<TimeLimit>();

  const TIME_LIMIT_COLUMNS = useTimeLimitColumns(setTimeLimitToDelete);

  const [{ orderByModel, paginationModel, whereFilterModel, visualFilterModel }, dispatch] = useImmerReducer(reducer, {
    whereFilterModel: { items: [] },
    visualFilterModel: { items: [] },
    orderByModel: [],
    paginationModel: DEFAULT_PAGINATION_MODEL,
  });

  const filter = useMemo<GetTimeLimitsFilter | undefined>(() => {
    const dateFields = [PURCHASE_ORDER_FIELDS.ORDER_DATE, PURCHASE_ORDER_FIELDS.DELIVERY_DATE];

    const whereGroups = getWhereFilterByModelAndHandleDateEQ(whereFilterModel, dateFields);

    const paginatedRequestFilter = getPaginatedRequestFilter(
      paginationModel,
      getOrderByModel(orderByModel),
      whereGroups,
    );

    if (paginatedRequestFilter.orderByFields?.length === 0) {
      paginatedRequestFilter.orderByFields = [
        { field: PURCHASE_ORDER_FIELDS.ORDER_DATE, operator: ORDER_BY_OPERATOR.DESC },
      ];
    }

    return paginatedRequestFilter;
  }, [whereFilterModel, paginationModel, orderByModel]);

  const { timeLimits, count, isLoading, refetchTimeLimits } = useGetTimeLimits({
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    filter,
  });

  const onFilterChange = useCallback(
    (gridFilterModel: GridFilterModel) => {
      dispatch({
        type: ACTION.CHANGE_WHERE_FILTER_MODEL,
        whereFilterModel: gridFilterModel,
        columns: TIME_LIMIT_COLUMNS,
      });
    },
    [dispatch, TIME_LIMIT_COLUMNS],
  );

  const onSortModelChange = useCallback(
    (gridSortModel: GridSortModel) => {
      dispatch({ type: ACTION.CHANGE_ORDER_BY_MODEL, orderByModel: gridSortModel });
    },
    [dispatch],
  );

  const changePaginationModel = useCallback(
    (changedPaginationModel: GridPaginationModel) => {
      dispatch({ type: ACTION.CHANGE_PAGINATION_MODEL, paginationModel: changedPaginationModel });
    },
    [dispatch],
  );

  useImperativeHandle(ref, () => {
    return { reload: () => refetchTimeLimits() };
  });

  return (
    <>
      <DataGrid
        autoHeight
        loading={isLoading}
        unstable_headerFilters
        pagination
        paginationMode={"server"}
        paginationModel={paginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
        onPaginationModelChange={changePaginationModel}
        rows={timeLimits}
        rowCount={count}
        columns={TIME_LIMIT_COLUMNS}
        filterMode={"server"}
        filterModel={visualFilterModel}
        onFilterModelChange={onFilterChange}
        sortingMode={"server"}
        sortModel={orderByModel}
        onSortModelChange={onSortModelChange}
        disableRowSelectionOnClick
      />
      <TimeLimitDeleteDialog
        timeLimitToDelete={timeLimitToDelete}
        setTimeLimitToDelete={setTimeLimitToDelete}
        refetchTimeLimits={refetchTimeLimits}
      />
    </>
  );
});

export default TimeLimitDataGrid;
