import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { CreateTimeLimitRequest } from "../dataTypes";
import { createTimeLimit } from "../reducers/createTimeLimit";
import { selectErrorMessage, selectRequestState } from "../timeLimitSelectors";

const useCreateTimeLimit = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const doCreateTimeLimit = useCallback(
    async ({ createTimeLimitRequest }: { createTimeLimitRequest: CreateTimeLimitRequest }) => {
      if (isLoading) return;

      await dispatch(createTimeLimit({ createTimeLimitRequest }));
    },
    [dispatch, isLoading],
  );

  return {
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    createTimeLimit: doCreateTimeLimit,
    errorMessage,
  };
};

export default useCreateTimeLimit;
