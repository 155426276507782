import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useCallback, useState } from "react";
import { useImmer } from "use-immer";
import { useAppDispatch } from "../../app/hooks";
import { setInfos } from "../../features/dialog/dialogSlice";
import useCreateTimeLimit from "../../features/timeLimit/hooks/useCreateTimeLimit";
import useTranslate from "../../language/useTranslate";
import Button from "../shared/PostNord/Button/Button";
import InputField from "../shared/PostNord/InputField/InputField";

interface Errors {
  ownerIdBlank?: boolean;
  ownerTypeBlank?: boolean;
  typeBlank?: boolean;
  daysBlank?: boolean;
}

const TimeLimitCreateDialog = ({
  createDialogOpen,
  setCreateDialogOpen,
  refetchTimeLimits,
}: {
  createDialogOpen: boolean;
  setCreateDialogOpen: (open: boolean) => void;
  refetchTimeLimits: () => void;
}) => {
  const [ownerId, setOwnerId] = useState<string>();
  const [ownerType, setOwnerType] = useState<string>();
  const [type, setType] = useState<string>();
  const [days, setDays] = useState<string>();
  const [errors, setErrors] = useImmer<Errors>({
    ownerIdBlank: false,
    ownerTypeBlank: false,
    typeBlank: false,
    daysBlank: false,
  });

  const appDispatch = useAppDispatch();
  const translate = useTranslate();
  const { createTimeLimit, isLoading, isSuccess } = useCreateTimeLimit();

  const handleCreateTimeLimit = useCallback(async () => {
    if (ownerId == null || ownerType == null || type == null || days == null) {
      setErrors(draft => {
        draft.ownerIdBlank = ownerId == null;
        draft.ownerTypeBlank = ownerType == null;
        draft.typeBlank = type == null;
        draft.daysBlank = days == null;
      });

      return;
    }

    await createTimeLimit({
      createTimeLimitRequest: { ownerID: ownerId, ownerType, type, days: Number(days) },
    });

    if (isSuccess) {
      appDispatch(setInfos([{ code: "time.limit.deleted", title: translate("CREATE_SUCCESSFUL") }]));
      setCreateDialogOpen(false);
      refetchTimeLimits();
    }
  }, [
    appDispatch,
    createTimeLimit,
    days,
    isSuccess,
    ownerId,
    ownerType,
    refetchTimeLimits,
    setCreateDialogOpen,
    setErrors,
    translate,
    type,
  ]);

  return (
    <Dialog
      open={createDialogOpen}
      onClose={() => setCreateDialogOpen(false)}
      PaperProps={{ sx: { width: "-webkit-fill-available" } }}
    >
      <DialogTitle>{translate("CREATE")}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <InputField
          label={translate("OWNER_ID")}
          onChange={e => setOwnerId(e.target.value)}
          value={ownerId}
          error={errors.ownerIdBlank}
          sx={{ marginTop: "1rem" }}
        />
        <InputField
          label={translate("OWNER_TYPE")}
          onChange={e => setOwnerType(e.target.value)}
          value={ownerType}
          error={errors.ownerTypeBlank}
        />
        <InputField
          label={translate("TYPE")}
          onChange={e => setType(e.target.value)}
          value={type}
          error={errors.typeBlank}
        />
        <InputField
          type="number"
          label={translate("DAYS")}
          onChange={e => setDays(e.target.value)}
          value={days}
          error={errors.daysBlank}
        />
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box display="flex" flexDirection="row" alignItems="flex-end">
            <Button label={translate("CANCEL")} onClick={() => setCreateDialogOpen(false)} />
            <Button
              label={translate("OK")}
              isLoading={isLoading}
              onClick={() => {
                handleCreateTimeLimit();
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TimeLimitCreateDialog;
