import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { AdditionalPaginationState, EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { CreateTimeLimitRequest, TimeLimit, timeLimitSliceName } from "../dataTypes";
import { timeLimitAdapter } from "../timeLimitAdapter";

async function doCreateTimeLimitRequest(
  dispatch: AppDispatch,
  { createTimeLimitRequest }: { createTimeLimitRequest: CreateTimeLimitRequest },
) {
  const request = new Request(api.timeLimit.createTimeLimit(), Method.POST);

  return await sendRequest(request, createTimeLimitRequest, await defaultRestOptions({ dispatch }));
}

interface Response {
  timeLimit: TimeLimit;
}

export const createTimeLimit = createAsyncThunk<Response, { createTimeLimitRequest: CreateTimeLimitRequest }>(
  timeLimitSliceName + "/createTimeLimit",
  doCreateTimeLimitRequest,
);

export const addCreateTimeLimitsReducers = (
  builder: EntityActionReducerBuilderInterface<TimeLimit, AdditionalPaginationState>,
) => {
  builder.addCase(createTimeLimit.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(createTimeLimit.fulfilled, (state, action) => {
    const { payload } = action;

    timeLimitAdapter.addOne(state, payload.timeLimit);
    state.requestState = REQUEST_STATE.FULFILLED;
    state.count += 1;
  });
  builder.addCase(createTimeLimit.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
